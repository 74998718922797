import React from 'react';
import jsonString from 'json-superstring';

const JsonLd = ({ data }) => (
  <script
    type="application/ld+json"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: jsonString(data),
    }}
  />
);

export default JsonLd;

import React from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

export const type = () => 'WebApplication';
export const id = (slug) =>
  `https://timelesstime.co.uk/tools/${slug}#${type()}/`;

const orgId = organizationId();

const ToolStructuredData = ({
  slug,
  title,
  description,
  createdAt,
  updatedAt,
  isFree,
  plaintextContent,
}) => (
  <JsonLd
    data={{
      '@context': 'http://schema.org',
      '@type': type(),
      '@id': id(slug),
      mainEntityOfPage: `https://timelesstime.co.uk/tools/${slug}`,
      applicationCategory: 'HR and Management Business Tool',
      browserRequirements: 'Requires JavaScript and HTML5',
      operatingSystem: 'Any operating system with an HTML5 browser',
      name: title,
      text: plaintextContent,
      description,
      creator: {
        '@id': orgId,
      },
      publisher: {
        '@id': orgId,
      },
      author: {
        '@id': orgId,
      },
      copyrightHolder: {
        '@id': orgId,
      },
      provider: {
        '@id': orgId,
      },
      copyrightYear: format(createdAt, 'yyyy'),
      inLanguage: {
        '@type': 'Language',
        name: 'English',
        alternateName: 'en',
      },
      isAccessibleForFree: isFree,
      datePublished: format(createdAt, 'yyyy-MM-dd'),
      dateCreated: format(createdAt, 'yyyy-MM-dd'),
      dateModified: format(updatedAt, 'yyyy-MM-dd'),
      offers: {
        '@type': 'Offer',
        price: '0',
        priceCurrency: 'GBP',
      },
    }}
  />
);

ToolStructuredData.defaultProps = {
  createdAt: undefined,
  updatedAt: undefined,
};

ToolStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  plaintextContent: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
};

export default ToolStructuredData;

import { useStaticQuery, graphql } from 'gatsby';

const useContactDetails = () => {
  const { contactDetails } = useStaticQuery(graphql`
    query useContactDetails {
      timelessTimeLogo: file(name: { eq: "TimelessTime-Logo-Square" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: NONE, layout: FIXED)
        }
      }
      contactDetails: allContentfulContactDetails(
        sort: { fields: [order], order: ASC }
      ) {
        nodes {
          id
          companyName
          address1
          address2
          address3
          town
          county
          postCode
          phoneNumber1
        }
      }
    }
  `);
  return contactDetails;
};

export default useContactDetails;

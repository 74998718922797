import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { Box, Text, Container, SimpleGrid } from '@chakra-ui/react';
import HomeHeroContent from 'timelesstime-ui/components/home-hero-content';
import BgImage from 'timelesstime-ui/components/bg-image';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Heading from 'timelesstime-ui/components/heading';
import SEO from 'timelesstime-ui/components/seo';
import OrganizationStructuredData from 'timelesstime-ui/components/json-ld';
import PageLayout from '../components/layout';
import ToolCard from '../components/tool-card';

const ToolsPage = ({
  data: {
    jsonLdFeaturedImage,
    page: {
      title,
      metaKeywords,
      metaDescription,
      splashImage,
      strapline,
      leadInText,
      heroTitle,
      heroText,
      heroImage,
      heroPrimaryCallToActionUrl,
      heroPrimaryCallToActionText,
      heroSecondaryCallToActionUrl,
      heroSecondaryCallToActionText,
    },
    allContentfulTool: { nodes: tools },
  },
}) => (
  <PageLayout>
    <SEO
      title={title}
      keywords={metaKeywords}
      description={metaDescription}
      canonicalPath="/"
      thumbnail={jsonLdFeaturedImage}
    />
    <OrganizationStructuredData />

    <BgImage as="section" bg={getImage(splashImage)} preload adaptiveLoading>
      <Box pb={8}>
        <Heading
          as="h1"
          m={0}
          fontSize={['3xl', '3xl', '4xl']}
          maxW="1000px"
          w="90vw"
          color="white"
          textAlign="center"
          marginX="auto"
          paddingTop={8}
        >
          {strapline}
        </Heading>
        <Heading
          as="h2"
          fontSize={['2xl', '2xl', '3xl']}
          maxW="1000px"
          w="90vw"
          color="white"
          textAlign="center"
          marginX="auto"
          mt={8}
        >
          {leadInText}
        </Heading>
      </Box>
    </BgImage>

    <Container as="section" maxW="container.lg">
      <HomeHeroContent
        heroTitle={heroTitle}
        heroImage={heroImage}
        heroText={heroText}
        heroPrimaryCallToActionUrl={heroPrimaryCallToActionUrl}
        heroPrimaryCallToActionText={heroPrimaryCallToActionText}
        heroSecondaryCallToActionUrl={heroSecondaryCallToActionUrl}
        heroSecondaryCallToActionText={heroSecondaryCallToActionText}
      />
    </Container>

    <Container as="section" maxW="container.lg">
      <SimpleGrid columns={[1, 1, 2]} spacing={[4, 4, 8]}>
        {tools.map((tool) => (
          <ToolCard key={tool.id} as="article" tool={tool} />
        ))}
      </SimpleGrid>
      {tools.length < 1 && (
        <Box>
          <Heading as="h1">Oops, looks like nothing was found.</Heading>
          <Text>
            That doesn&apos;t mean all hope is lost.{' '}
            <CanonicalLink to="/contact/">Get in touch</CanonicalLink>,
            we&apos;d love to help you.
          </Text>
        </Box>
      )}
    </Container>
  </PageLayout>
);

export const query = graphql`
  query ToolsPageQuery {
    jsonLdFeaturedImage: file(name: { eq: "TimelessTime-FrontPage-Logo" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED, layout: FIXED)
      }
    }
    page: contentfulHomePage(slug: { eq: "tools-home" }) {
      title
      metaDescription
      metaKeywords
      strapline
      splashImage {
        ...SplashImage
      }
      leadInText
      heroTitle
      heroImage {
        title
        gatsbyImageData(width: 250, placeholder: BLURRED, layout: CONSTRAINED)
      }
      heroPrimaryCallToActionText
      heroPrimaryCallToActionUrl
      heroSecondaryCallToActionText
      heroSecondaryCallToActionUrl
      fields {
        heroTextHtml
      }
    }
    allContentfulTool(
      sort: { fields: [ordering, createdAt], order: DESC }
      filter: { ordering: { ne: -1 } }
    ) {
      nodes {
        id
        title
        slug
        metaDescription
        createdAt
        updatedAt
        isFree
        credits
        fields {
          path
          url
          writtenAt
          isNew
          plaintextContent
          excerptHtml
        }
      }
    }
  }
`;

export default ToolsPage;

import React from 'react';
import PropTypes from 'prop-types';
import useSiteMetadata from 'timelesstime-gatsby-utils/hooks/useSiteMetadata';

import JsonLd from './json-ld';

const urlWithDomain = (siteUrl, url) => url.replace(/^\//, `${siteUrl}/`);

const BreadcrumbsStructuredData = ({ crumbs }) => {
  const { siteUrl } = useSiteMetadata();
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: crumbs.map((crumb, index) => ({
          '@type': 'ListItem',
          position: index + 1,
          item: {
            '@id': `${urlWithDomain(siteUrl, crumb.url)}#Breadcrumb`,
            url: urlWithDomain(siteUrl, crumb.url),
            name: crumb.title,
          },
        })),
      }}
    />
  );
};

BreadcrumbsStructuredData.defaultProps = {};

BreadcrumbsStructuredData.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BreadcrumbsStructuredData;

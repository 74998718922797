import React from 'react';
import { Box, Tooltip, Badge, Flex, Divider } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Html from 'timelesstime-ui/components/html';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import ToolStructuredData from '../structured-data/tool';

const ToolCard = ({ tool, ...props }) => (
  <Box {...props}>
    <ToolStructuredData
      slug={tool.slug}
      title={tool.title}
      description={tool.metaDescription}
      caseStudies={tool.caseStudies || []}
      isFree={!!tool.isFree}
      plaintextContent={tool.fields.plaintextContent}
      createdAt={new Date(tool.createdAt)}
      updatedAt={new Date(tool.updatedAt)}
      shortMeta
    />

    <Box>
      <Box as="header">
        <Heading as="h1" mt={0} mb={1}>
          <CanonicalLink to={tool.fields.path}>{tool.title}</CanonicalLink>
        </Heading>
        {(tool.isFree || (!tool.isFree && tool.credits)) && (
          <Box mb={6}>
            <Flex mt={3} mb={3}>
              {tool.isFree && (
                <Tooltip
                  aria-label="Free tool"
                  label="This tool is free to use"
                >
                  <Badge colorScheme="green" variant="solid">
                    Free
                  </Badge>
                </Tooltip>
              )}
              {!tool.isFree && tool.credits && (
                <Tooltip
                  aria-label="Premium tool"
                  label="This is a premium tool"
                >
                  <Badge colorScheme="blue" variant="solid">
                    {tool.credits} credits
                  </Badge>
                </Tooltip>
              )}
            </Flex>
            <Divider />
          </Box>
        )}
      </Box>

      <Html source={tool.fields.excerptHtml} headerLevelStart={1} />
    </Box>

    <Flex justify="center">
      <ButtonLink
        to={tool.fields.path}
        colorScheme="orange"
        color="white"
        mt={4}
      >
        {tool.useToolText ? tool.useToolText : 'Get Started'}
      </ButtonLink>
    </Flex>
  </Box>
);

export default ToolCard;

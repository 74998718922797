import React from 'react';
import { pascalCase } from 'pascal-case';
import { getSrc, getImage } from 'gatsby-plugin-image';
import useContactDetails from 'timelesstime-gatsby-utils/hooks/useContactDetails';
import useLogos from 'timelesstime-gatsby-utils/hooks/useLogos';

import JsonLd from './json-ld';

export const type = () => 'LocalBusiness';
export const id = () => 'https://timelesstime.co.uk/#Organization';

export default () => {
  const contactDetails = useContactDetails();
  const { logoSquare_w200: logoSquare } = useLogos();
  return (
    <JsonLd
      data={{
        '@context': 'http://schema.org',
        '@type': type(),
        '@id': id(),
        url: 'https://timelesstime.co.uk/',
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://timelesstime.co.uk/#TimelessTimeLogo',
          url: `https://timelesstime.co.uk${getSrc(getImage(logoSquare))}`,
          width: getImage(logoSquare).width,
          height: getImage(logoSquare).height,
        },
        image: {
          '@id': 'https://timelesstime.co.uk/#TimelessTimeLogo',
        },
        name: 'TimelessTime',
        legalName: 'TimelessTime',
        description:
          'Management consultants specialising in human resource management, management practices & organisational development.',
        priceRange:
          '£500 to £50000, tailored to the client and the work required',
        email: 'info@timelesstime.co.uk',
        vatID: 'GB 102 3367 60',
        hasMap:
          'https://www.google.com/maps/place/TimelessTime/@50.9858649,-0.0930768,14.7z',
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '50.9858649',
          longitude: '-0.0930768',
        },
        address: contactDetails.nodes.map(
          ({
            companyName,
            address1,
            address2,
            address3,
            town,
            county,
            postCode,
            phoneNumber1,
          }) => ({
            '@id': `https://timelesstime.co.uk/contact/#${pascalCase(
              address1,
            )}`,
            '@type': 'PostalAddress',
            name: companyName,
            addressCountry: 'GB',
            addressLocality: town,
            addressRegion: county,
            postalCode: postCode,
            streetAddress: [address1, address2, address3]
              .filter((part) => !!part)
              .join(', '),
            email: 'info@timelesstime.co.uk',
            telephone: [phoneNumber1],
          }),
        ),
        contactPoint: contactDetails.nodes.map(({ address1 }) => ({
          '@id': `https://timelesstime.co.uk/contact/#${pascalCase(address1)}`,
        })),
        openingHours: 'Mo-Su',
        openingHoursSpecification: {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          opens: '00:00:00',
          closes: '23:59:59',
        },
        sameAs: [
          'https://www.youtube.com/user/TimelessTimeHR',
          'https://www.linkedin.com/company/timelesstime-ltd',
          'https://twitter.com/TimelessTimeLtd',
        ],
      }}
    />
  );
};
